import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from "../models/user";


function AuthWrapper(props) {
	let { RouterComponent, path, routes } = props;

	let { user } = useContext(UserContext);

	let authed = user.isAuthed;


	return <Route
		path={path}
		render={props => (authed ? <RouterComponent {...props} routes={routes}/> : <Redirect to={"/"}/>)}
	/>;
}

export default AuthWrapper;