import axios from 'axios';
import { message } from 'antd';

export type listType<T> = {
  results: Array<T>;
  count: number;
};

export function to(promise: Promise<any>) {
  return promise
    .then(data => {
      return [null, data];
    })
    .catch(err => [err]);
}

function buildStringArr(params: any): Array<any> {
  let str = [];
  if (!params) {
    return [];
  }
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      if (Array.isArray(params[p])) {
        let s = [];
        let list = params[p];
        for (let i in list) {
          s.push(encodeURIComponent(list[i]));
        }
        str.push(encodeURIComponent(p) + '=' + s.join(','));
      } else {
        if (params[p] !== null)
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }
  }

  return str;
}

export const createCookieString = (params: any) => {
  let str = buildStringArr(params);
  return str.join('&');
};

export const createParamsString = (params: any, spiltor: string = ',') => {
  let str = buildStringArr(params);
  return '?' + str.join('&');
};

export const getTokenInCookie = () => {
  let name = 'token=';
  // @ts-ignore
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    let item = c.split('&');
    for (let j = 0; j < item.length; j++) {
      let it = item[j];
      if (it.indexOf(name) === 0) {
        return it.substring(name.length, it.length);
      }
    }
  }

  return null;
};
export const HOST = (() => {
  if (process.env.NODE_ENV === 'development') {
    //开发调试
    // return "http://192.168.31.55:8000"
    // return "http://192.168.0.37:7000"
    // return "http://192.168.0.8:8000"
    // return "http://1.14.156.250:8000"
    // return 'http://1.14.156.250:8001'
    // return `http://192.168.31.177:8000`
    // return 'http://192.168.31.10:8001'
    // return 'http://110.40.213.15:8000'
  }
  //线上
  return 'https://api-changsha.jialeijob.com';
})();

axios.defaults.baseURL = HOST + '/api';
// axios.defaults.baseURL = "https://api-test.jialeijob.com/api"
// axios.defaults.baseURL = "http://192.168.0.37:7000/api"

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.data) {
      if (error.response.data && error.response.status === 400) {
        message.error(error.response.data.reason);
      } else if (error.response.status === 403) {
        message.error('验证失效，请退出重新登录');
      } else {
        message.error(
          `服务器连接错误,错误码：${error.response.status}，有问题请联系客服微信：yphfandi`
        );
      }
    }
    //message.error(error.data.reason);
    return Promise.reject(error);
  }
);

export interface ReducerAction {
  type: string;
  data?: any;
}

export async function putData(url: string, data: any): Promise<any> {
  // const token = getTokenInCookie();
  return axios.put(url, data);
}

export async function putDataWithToken(url: string, data: any): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({status: 403});
  }
  return axios.put(url, data, {headers: {jwt: token}});
}

export async function postDataWithToken(url: string, data: any): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({status: 403});
  }
  return axios.post(url, data, {headers: {jwt: token}});
}

export async function getDataWithToken(url: string, params?: object): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({status: 403});
  }
  let paramsString = createParamsString(params);
  return axios.get(`${url}${paramsString}`, {headers: {jwt: token},});
}

export async function deleteDataWithToken(url: string): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({status: 403});
  }

  return axios.delete(`${url}`, {headers: {jwt: token}});
}

export async function getData(url: string, params?: object): Promise<any> {
  let paramsString = createParamsString(params);
  let requestUrl = `${url}${paramsString}`;

  return axios.get(requestUrl);

  //return service.get(url);
}

export async function postData(url: string, data: object): Promise<any> {
  //return axios.get()
  return to(axios.post(url, data));
}
