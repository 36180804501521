import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from "antd";
import { UserContext } from "../../models/user";
import { Link, useHistory } from "react-router-dom";
import { getTokenInCookie } from '../../models/utils';
import Logo from "../../assets/images/yphlogo.png";
import './style.less';

function App() {
	let {login, user} = useContext(UserContext);
	let history = useHistory();
	const [show, setShow] = useState(false)
	//登录成功，进入主页
	const gotoCompany = () => {
		
		history.push('/home');
	}
	
	const onFinish = (values: any) => {
		values.username = values.username.trim()
		login(values, gotoCompany);
		
	}
	
	let token = getTokenInCookie();
	
	useEffect(() => {
		if (user?.isAuthed && token) {
			history.push('');
		}
	}, []);
	
	
	return (
		
		<div className="yph-login-page yph-public-bg-page">
			
			<div className={"form"}>
				<div className="logo-box">
					<img src={Logo} style={{height: '53px', width: "53px"}} alt="logo"/>
					<span className="title">长沙县智慧星零工就业创业服务平台</span>
				</div>
				
				<Form
					onFinish={onFinish}
					layout={"vertical"}
				>
					<div className={"item"}>
						<Form.Item
							label="登录账号"
							name="username"
							rules={[{required: true, message: '请输入用户名!'}]}
						>
							<Input style={{borderRadius: "8px"}} placeholder="登录邮箱" size="large"/>
						</Form.Item>
					
					</div>
					<div className={"item"}>
						<Form.Item
							label="登录密码"
							name="password"
							rules={[{required: true, message: '请输入密码!'}]}
						>
							<Input.Password placeholder="登录密码" style={{borderRadius: "8px"}}
															size="large"/>
						</Form.Item>
					
					</div>
					
					
					<Button style={{borderRadius: 8}} size="large" type="primary" htmlType="submit" block>登录</Button>
					
					
					<div className={"register"}>
						<Link to="/user">用户注册</Link>
					
					</div>
					
					<div className="options">
						<Link to="/forgotpassword">忘记密码?</Link>
						<span onClick={()=>setShow(true)}>联系客服</span>
					</div>
				</Form>
			</div>
			
			<Modal footer={null} title={"客服微信"} visible={show} onCancel={()=>setShow(false)}>
			
			</Modal>
		</div>
	
	
	);
}

export default App;