import asyncComponent from './utils/AsyncComponent';

const Login = asyncComponent(() => import("./pages/login/login"));

const Register = asyncComponent(() => import("./pages/login/register"));

const Company = asyncComponent(() => import("./pages/company"));

const UserCreate = asyncComponent(() => import("./pages/user/UserCeate"));

const UserHome = asyncComponent(() => import("./pages/user/UserHome"));

const CompanyCreate = asyncComponent(() => import("./pages/company/"));

const CompanyApp = asyncComponent(() => import("./pages/companyApp"));

//const HroApp =  asyncComponent(() => import("./pages/hroApp"));

//const HroCreate =  asyncComponent(() => import("./pages/hro"));

const userBind = asyncComponent(() => import("./pages/user/userBind"));

const ForgotPassword = asyncComponent(() => import("./pages/login/forgotpassword"));


const ResetPassword = asyncComponent(() => import("./pages/login/resetPassword"));

//ats-miniapp iconfont 展示
const AtsMiniAppIconfontDoc = asyncComponent(() => import("./pages/docs/atsMiniAppIcon"));

const AtsPublicPage = asyncComponent(() => import("./pages/docs/publicPage"));

const LiveVideo = asyncComponent(() => import("./pages/docs/dhbd"));


const Institutions = asyncComponent(() => import("./pages/institutions/index"))

export const privateRoutes = [

  {
    path: '/c',
    component: Company,
    exact: true
  },
  {
    path: '/userbind',
    component: userBind,
    exact: true
  },
  {
    path: '/home',
    component: UserHome,
    exact: true
  },
  {
    path: '/company/:step',
    component: CompanyCreate,
  },
  {
    path: '/app/:company_id',
    component: CompanyApp
  },
  {
    path: "/institutions/:step",
    component: Institutions
  }


];

export const publicRoutes = [
  {
    path: '/',
    component: Login,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/user',
    component: UserCreate,
    exact: true
  },
  {
    path: '/register',
    component: Register,
    exact: true
  },
  {
    path: '/forgotpassword',
    component: ForgotPassword,
    exact: true

  },
  {
    path: '/resetpassword',
    component: ResetPassword,
    exact: true
  },
  {
    path: '/ats-miniapp-icon',
    component: AtsMiniAppIconfontDoc,
    exact: true
  },
  {
    path: '/live',
    component: LiveVideo,
    exact: true
  },
  {
    path: '/pp',
    component: AtsPublicPage,
    exact: true
  },
];



