enum requestUrl {
  login = '/token-auth/',
  register = '/user-registration/',
  user = '/users/{user_id}/',
  binduser = '/companies/bind_wechat_account/',
  companyDetail = '/company',
  loginQr = '/companies/get_login_qr_code_url/',
  userProfile = '/user_profiles/{user_id}/',
  loginWithQr = '/companies/login_status/',
  company = '/companies/',
  companyLogo = '/companies/{company_id}/images/',
  companyLicense = '/companies/{company_id}/upload/',
  companyJobs = '/companies/{company_id}/jobs/',
  companyFlexibleJobs = '/companies/{company_id}/jobs/',
  companyFlexibleJobDetail = '/companies/{company_id}/jobs/{odd_job_market_id}/',
  companyJobPublish = '/companies/{company_id}/jobs/{id}/brand/platform_list/',
  checkCompanyname = '/users/check_user_identification/',
  jobResume = '/companies/{company_id}/jobs/{job_id}/candidates/',


  //
  flexibleJobResume = '/companies/{company_id}/jobs/{job_id}/candidates/',
  //修改零工简历状态
  putFlexibleJobResume = "/companies/{company_id}/candidates/{candidate_id}/company_marked_information/",

  publicResumeDetail = '/companies/{company_id}/career_fairs/{career_fair_id}/resumes/{resume_id}/',
  candidates = '/companies/{company_id}/candidates/',
  candidate = '/companies/{company_id}/candidates/{candidate_id}/',
  companyResume = '/companies/{company_id}/resumes/{resume_id}/',
  publicResume = '/companies/{company_id}/career_fairs/all_cf_resumes/',
  publicCustomResume = '/companies/{company_id}/career_fairs/all_cf_resumes/custom_search/',
  cfPubicResume = '/companies/{company_id}/career_fairs/{career_fair_id}/resumes/',
  saveResume = '/companies/{company_id}/career_fairs/{career_fair_id}/resumes/{resume_id}/save/',
  careerFairs = '/companies/{company_id}/career_fairs/',
  cfApply = '/companies/{company_id}/career_fairs/apply/',
  channels = '/message_board/jobs/channel_list/',
  messages = '/message_board/jobs/get_message_list/{channel_id}/',
  team = '/companies/{company_id}/accounts/',
  cfRitui = 'companies/{company_id}/career_fairs/{career_fair_id}/applied_ritui_list/',
  cfjobs = '/companies/{company_id}/jobs/career_fairs/{career_fair_id}/applied_list/',
  addJobToCf = '/companies/{company_id}/jobs/apply/',
  deleteJobFromCf = '/companies/{company_id}/jobs/{job_id}/remove_cf/career_fair/{career_fair_id}/',
  labels = '/companies/{company_id}/resume_lable_list/',
  interview = '/companies/{company_id}/candidates/{candidate_id}/events/',
  events = '/companies/{company_id}/new_events/',
  channelEvents = '/companies/{company_id}/new_events/',
  activateInterview = '/companies/{company_id}/candidates/{candidate_id}/events/{event_id}/activate/',
  recentVisitCount = '/companies/{company_id}/recent_visit_count/',
  recentCandidateCount = '/companies/{company_id}/recent_candidate_count/',
  recentCareerFairInformation = '/companies/{company_id}/recent_career_fair_information',
  publicUpload = `/public/upload/image/`,
  uploadExcel = `/companies/{company_id}/parse_examination_excel/`,
  exams = `companies/{company_id}/examination_list/`,
  startExams = `companies/{company_id}/examination_list/{exam_id}/send_examination_invitation/`,
  examsDetail = `/companies/{company_id}/examination_list/{examination_id}/`,
  exportCanidates = `/resume_format/companies/{company_id}/batch_pdf/`,
  clearReason = `/companies/{company_id}/clear_reason/`,
  sendSystemMessage = `/message_board/jobs/send_system_message/{channel_id}/`,
  //进入面试房间 put kind=1 企业小程序 kind=2 ats
  enterRoom = `/message_board/jobs/enter_room/`,
  closeRoom = `/message_board/jobs/close_room/`,
  exitRoom = `/message_board/jobs/exit_room/`,
  schoolList = `/public/get_school_list/`,
  customSearchCandidate = `/companies/{company_id}/candidates/custom_search/`,

}

export default requestUrl;